import type { FC } from 'react';
import { useFragment, graphql } from 'react-relay/hooks';
import styled from '@emotion/styled';
import { ButtonLink, Icon } from '@pafcloud/base-components';
import { Breakpoint } from '@pafcloud/style';
import { useFormatAmount } from '@pafcloud/react-hook-utils';
import type { HeroGame_data$key } from './__generated__/HeroGame_data.graphql';
import { HeroGameDecorations } from './HeroGameDecorations';
import { HeroGameTile } from './HeroGameTile';

const dataFragment = graphql`
  fragment HeroGame_data on HeroGame {
    ...HeroGameDecorations_data
    game {
      ...HeroGameTile_game
      jackpot {
        currencyCode
        amount
      }
      launchUrl
    }
    size
    assets {
      bottomLeft {
        __typename
      }
      bottomRight {
        __typename
      }
      topLeft {
        __typename
      }
      topRight {
        __typename
      }
    }
  }
`;

type GameProps = {
  hasBottomAsset: boolean;
  hasTopAsset: boolean;
  increaseMarginTop?: boolean;
  size?: 'PROMOTED' | null;
};

const Game = styled.div<GameProps>(
  ({ size = 'hero' }) => ({
    position: 'relative',
    display: 'block',
    maxWidth: size === 'PROMOTED' ? 320 : 192,
    margin: '32px auto',

    [Breakpoint.LandscapePhone]: {
      maxWidth: 160,
      margin: '16px auto',
    },
  }),
  ({ hasBottomAsset, hasTopAsset }) => ({
    width: !hasBottomAsset && !hasTopAsset ? 'calc(100% - 48px)' : '61.8%',
    marginTop: hasTopAsset ? '10vw' : undefined,
    marginBottom: hasBottomAsset ? '10vw' : undefined,

    [Breakpoint.LaptopOrLarger]: {
      marginTop: hasTopAsset ? '64px' : undefined,
      marginBottom: hasBottomAsset ? '64px' : undefined,
    },
  }),
);

const GameButton = styled(ButtonLink)({
  position: 'absolute',
  bottom: 0,
  left: '50%',
  transform: 'translate(-50%, 50%)',
  width: 'max-content',
  minWidth: '50%',
  maxWidth: '90%',
  textAlign: 'center',

  // Breaking our mobile first rule.
  // The button has correct styling for mobile and desktop we have to override it here.
  [Breakpoint.Phone]: {
    minHeight: 'max(min(48px, 10.5vmin), 32px)',
    letterSpacing: '-0.02em',
    '--button-hero-font-size': '1rem',
  },
  [Breakpoint.LandscapePhone]: {
    minHeight: 'max(min(48px, 10.5vmin), 32px)',
    letterSpacing: '-0.02em',
    '--button-hero-font-size': '1rem',
  },
});

type HeroGameComponentProps = {
  data: HeroGame_data$key;
  buttonText: string | null;
  className?: string;
  loading?: 'lazy' | 'eager';
  promotedSize?: boolean;
  onHeroClick: () => void;
};

export const HeroGame: FC<HeroGameComponentProps> = ({
  data,
  buttonText,
  className,
  promotedSize,
  loading,
  onHeroClick,
}) => {
  const formatAmount = useFormatAmount();

  const fragmentData = useFragment(dataFragment, data);
  const { game, size, assets } = fragmentData;
  const { topLeft, topRight, bottomLeft, bottomRight } = assets;

  return (
    <Game
      hasTopAsset={!!topLeft || !!topRight}
      hasBottomAsset={!!bottomLeft || !!bottomRight}
      className={className}
      size={promotedSize ? 'PROMOTED' : size}
    >
      <HeroGameTile data={game} loading={loading} onClick={onHeroClick} />

      <HeroGameDecorations data={fragmentData} />

      {buttonText || game.jackpot ? (
        <GameButton href={`${game.launchUrl}`} variant="primary" size="hero" onClick={onHeroClick}>
          {game.jackpot ? (
            <span>
              <Icon name="jackpot" size="1em" />
              {formatAmount(game.jackpot.amount, {
                decimals: false,
                currencyCode: game.jackpot.currencyCode,
              })}
            </span>
          ) : (
            buttonText
          )}
        </GameButton>
      ) : null}
    </Game>
  );
};
