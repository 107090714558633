/**
 * @generated SignedSource<<372e17a820c6fc47049c6403bd6049e3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type HeroAsset_data$data = {
  readonly image: {
    readonly height: number;
    readonly url: string;
    readonly width: number;
  };
  readonly " $fragmentType": "HeroAsset_data";
};
export type HeroAsset_data$key = {
  readonly " $data"?: HeroAsset_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"HeroAsset_data">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "HeroAsset_data",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Image",
      "kind": "LinkedField",
      "name": "image",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "url",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "height",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "width",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "HeroAsset",
  "abstractKey": null
};

(node as any).hash = "9c27fb4d56ca94cd706c50d357a6de07";

export default node;
