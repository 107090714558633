import type { FC } from 'react';
import { graphql, useFragment } from 'react-relay/hooks';
import styled from '@emotion/styled';
import { useRouter } from 'next/router';
import { useTranslation } from '@pafcloud/i18n';
import { InternalLink, Image } from '@pafcloud/base-components';
import { rememberGameHomeUrl } from '@pafcloud/react-hook-utils';
import { useGetTurnoverContribution } from '@pafcloud/game-components';
import { useHasActiveTurnoverStep } from '@pafcloud/contexts';
import { Color } from '@pafcloud/style';
import type { HeroGameTile_game$data, HeroGameTile_game$key } from './__generated__/HeroGameTile_game.graphql';

const dataFragment = graphql`
  fragment HeroGameTile_game on Game {
    name
    familyName
    launchUrl
    supplier
    turnoverContribution
    status
    new
    thumbnail
    ...useGetTurnoverContribution_game
    ...useTrackGame_game
  }
`;

const GameLink = styled(InternalLink)({
  position: 'relative',
  display: 'block',
  isolation: 'isolate',
});

const GameThumbnailWrapper = styled.div({
  position: 'relative',
  overflow: 'hidden',
  borderRadius: Color.CornerRadius.Large,
  boxShadow: Color.Elevation.Level3,
});

const GameThumbnail = styled(Image)({
  display: 'block',
  maxWidth: '100%',
  height: 'auto',
  maxHeight: '100%',
  aspectRatio: '1 / 1',
  objectFit: 'cover',
});

const TurnoverGlow = styled.div({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  borderRadius: Color.CornerRadius.Large,
  border: `2px solid ${Color.Primitive.Primary}`,
  boxShadow: `0 3px 24px 2px ${Color.Primitive.Primary}`,
  zIndex: 1,
  pointerEvents: 'none',
});

type HeroGameTileProps = {
  data: HeroGameTile_game$key;
  loading?: 'eager' | 'lazy';
  onClick: (game: HeroGameTile_game$data) => void;
};

export const HeroGameTile: FC<HeroGameTileProps> = ({ data, loading, onClick }) => {
  const { asPath } = useRouter();
  const game = useFragment(dataFragment, data);
  const { t } = useTranslation('game-list');
  const hasActiveTurnover = useHasActiveTurnoverStep();
  const turnoverContribution = useGetTurnoverContribution(game);

  const showTurnover = hasActiveTurnover && !!turnoverContribution;

  if (game.status === 'MAINTENANCE') {
    return null;
  }

  return (
    <GameLink
      href={game.launchUrl}
      aria-label={t('game-item.link.aria-label', { gameName: game.name })}
      onClick={() => {
        onClick(game);
        rememberGameHomeUrl(asPath);
      }}
    >
      {showTurnover && <TurnoverGlow />}
      <GameThumbnailWrapper>
        {game.thumbnail && <GameThumbnail src={game.thumbnail} loading={loading} width={320} height={320} />}
      </GameThumbnailWrapper>
    </GameLink>
  );
};
