/**
 * @generated SignedSource<<4fa12f45d23e84b08d98432590200e6c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type HeroGameIconSize = "PROMOTED";
import { FragmentRefs } from "relay-runtime";
export type HeroGame_data$data = {
  readonly assets: {
    readonly bottomLeft: {
      readonly __typename: "HeroAsset";
    } | null;
    readonly bottomRight: {
      readonly __typename: "HeroAsset";
    } | null;
    readonly topLeft: {
      readonly __typename: "HeroAsset";
    } | null;
    readonly topRight: {
      readonly __typename: "HeroAsset";
    } | null;
  };
  readonly game: {
    readonly jackpot: {
      readonly amount: number;
      readonly currencyCode: string;
    } | null;
    readonly launchUrl: string;
    readonly " $fragmentSpreads": FragmentRefs<"HeroGameTile_game">;
  };
  readonly size: HeroGameIconSize | null;
  readonly " $fragmentSpreads": FragmentRefs<"HeroGameDecorations_data">;
  readonly " $fragmentType": "HeroGame_data";
};
export type HeroGame_data$key = {
  readonly " $data"?: HeroGame_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"HeroGame_data">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "__typename",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "HeroGame_data",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "HeroGameDecorations_data"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Game",
      "kind": "LinkedField",
      "name": "game",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "HeroGameTile_game"
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Jackpot",
          "kind": "LinkedField",
          "name": "jackpot",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "currencyCode",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "amount",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "launchUrl",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "size",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "HeroGameAssetFields",
      "kind": "LinkedField",
      "name": "assets",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "HeroAsset",
          "kind": "LinkedField",
          "name": "bottomLeft",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "HeroAsset",
          "kind": "LinkedField",
          "name": "bottomRight",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "HeroAsset",
          "kind": "LinkedField",
          "name": "topLeft",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "HeroAsset",
          "kind": "LinkedField",
          "name": "topRight",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "HeroGame",
  "abstractKey": null
};
})();

(node as any).hash = "f84720165db8401220f8645bc7f5a8ee";

export default node;
