import styled from '@emotion/styled';
import type { FC } from 'react';
import { graphql, useFragment } from 'react-relay/hooks';
import type { Icon as IconType } from '@pafcloud/icon';
import { Badge, Icon, NewBadge } from '@pafcloud/base-components';
import { useMedia } from '@pafcloud/react-hook-utils';
import { Breakpoint } from '@pafcloud/style';
import {
  GameItemRightCornerBadge,
  TurnoverBadge,
  BadgeSize,
  GameItemLeftCornerBadgeWrapper,
  useGetTurnoverContribution,
} from '@pafcloud/game-components';
import { useMatchGameToFreespins, useHasActiveTurnoverStep, useMatchGameToBingoTickets } from '@pafcloud/contexts';
import type { HeroGameDecorations_data$key } from './__generated__/HeroGameDecorations_data.graphql';
import { HeroAsset } from './HeroAsset';

const dataFragment = graphql`
  fragment HeroGameDecorations_data on HeroGame {
    game {
      new
      familyName
      turnoverContribution
      ...useGetTurnoverContribution_game
    }
    icon
    assets {
      bottomLeft {
        ...HeroAsset_data
      }
      bottomRight {
        ...HeroAsset_data
      }
      topLeft {
        ...HeroAsset_data
      }
      topRight {
        ...HeroAsset_data
      }
    }
  }
`;

const HeroGameBadge = styled(Badge)({
  position: 'absolute',
  pointerEvents: 'none',
  height: '20%',
  width: '20%',
  top: 0,
  left: 0,
  transform: 'translate(-15%, -15%)',
  border: 'none',
});

const HeroGameIcon = styled(Icon)({
  position: 'absolute',
  width: '50%',
  height: '50%',
  '> svg': {
    height: '100%',
    width: '100%',
  },
});

const DefaultAsset = styled.figure({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'absolute',
  pointerEvents: 'none',
  margin: 0,
  height: 'min(40%, 13vw)',
  width: 'min(40%, 13vw)',
});

const TopLeftAsset = styled(DefaultAsset)({
  top: 0,
  left: 0,
  transform: 'translate(-50%, -50%)',
});

const TopRightAsset = styled(DefaultAsset)({
  top: 0,
  right: 0,
  transform: 'translate(50%, -50%)',
});

const BottomLeftAsset = styled(DefaultAsset)({
  bottom: 0,
  left: 0,
  transform: 'translate(-50%, 50%)',
});

const BottomRightAsset = styled(DefaultAsset)({
  bottom: 0,
  right: 0,
  transform: 'translate(50%, 50%)',
});

type HeroGameDecorationsProps = {
  data: HeroGameDecorations_data$key;
};

export const HeroGameDecorations: FC<HeroGameDecorationsProps> = ({ data }) => {
  const { game, assets, icon } = useFragment(dataFragment, data);
  const { topLeft, topRight, bottomLeft, bottomRight } = assets;

  const isLandscapePhone = useMedia(Breakpoint.LandscapePhone, false);
  const size = isLandscapePhone ? 'normal' : 'large';

  const showHeroGameIcon = icon && !topLeft;
  const showNewBadge = !topLeft && !showHeroGameIcon && game.new;

  const matchGameToFreespins = useMatchGameToFreespins(game.familyName);
  const matchGameToBingoTickets = useMatchGameToBingoTickets(game.familyName);
  const shouldShowBonusProductBadge = !topRight && (!!matchGameToFreespins || !!matchGameToBingoTickets);

  const hasActiveTurnover = useHasActiveTurnoverStep();
  const turnoverContribution = useGetTurnoverContribution(game);

  return (
    <>
      {showHeroGameIcon && (
        <HeroGameBadge size={size}>
          <HeroGameIcon name={icon as IconType} />
        </HeroGameBadge>
      )}
      {showNewBadge && (
        <GameItemLeftCornerBadgeWrapper size={size} rotateBadge>
          <NewBadge size={BadgeSize[size].size} />
        </GameItemLeftCornerBadgeWrapper>
      )}
      {shouldShowBonusProductBadge && (
        <GameItemRightCornerBadge
          rounds={matchGameToFreespins?.spinsRemaining ?? 0}
          showFreespins={!!matchGameToFreespins}
          tickets={matchGameToBingoTickets?.ticketsRemaining ?? 0}
          showBingoTickets={!!matchGameToBingoTickets}
          size={size}
        />
      )}
      {hasActiveTurnover && turnoverContribution != null && (
        <TurnoverBadge turnoverContribution={turnoverContribution} size={size} />
      )}

      {topLeft && (
        <TopLeftAsset>
          <HeroAsset data={topLeft} />
        </TopLeftAsset>
      )}
      {topRight && (
        <TopRightAsset>
          <HeroAsset data={topRight} />
        </TopRightAsset>
      )}
      {bottomLeft && (
        <BottomLeftAsset>
          <HeroAsset data={bottomLeft} />
        </BottomLeftAsset>
      )}
      {bottomRight && (
        <BottomRightAsset>
          <HeroAsset data={bottomRight} />
        </BottomRightAsset>
      )}
    </>
  );
};
