/**
 * @generated SignedSource<<ba669b98d4705ba5ec7853f028248728>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type GameStatus = "ACTIVE" | "MAINTENANCE";
import { FragmentRefs } from "relay-runtime";
export type HeroGameTile_game$data = {
  readonly familyName: string;
  readonly launchUrl: string;
  readonly name: string;
  readonly new: boolean;
  readonly status: GameStatus;
  readonly supplier: string;
  readonly thumbnail: string;
  readonly turnoverContribution: number | null;
  readonly " $fragmentSpreads": FragmentRefs<"useGetTurnoverContribution_game" | "useTrackGame_game">;
  readonly " $fragmentType": "HeroGameTile_game";
};
export type HeroGameTile_game$key = {
  readonly " $data"?: HeroGameTile_game$data;
  readonly " $fragmentSpreads": FragmentRefs<"HeroGameTile_game">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "familyName",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "launchUrl",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "HeroGameTile_game",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "supplier",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "turnoverContribution",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "new",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "thumbnail",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useGetTurnoverContribution_game"
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "useTrackGame_game",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "category",
          "storageKey": null
        },
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "gameId",
          "storageKey": null
        },
        (v2/*: any*/),
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "provider",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "slug",
          "storageKey": null
        }
      ],
      "args": null,
      "argumentDefinitions": []
    }
  ],
  "type": "Game",
  "abstractKey": null
};
})();

(node as any).hash = "87352e4564b3ed8b5b2a3c26b450d3c5";

export default node;
