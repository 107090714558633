import type { FC } from 'react';
import { useFragment, graphql } from 'react-relay/hooks';
import styled from '@emotion/styled';
import { Image } from '@pafcloud/base-components';
import type { HeroAsset_data$key } from './__generated__/HeroAsset_data.graphql';

const dataFragment = graphql`
  fragment HeroAsset_data on HeroAsset {
    image {
      ...utils_imageProperties @relay(mask: false)
    }
  }
`;

const ImageAsset = styled(Image)({
  pointerEvents: 'none',
  objectFit: 'contain',
  maxWidth: '100%',
  maxHeight: '100%',
});

type HeroAssetComponentProps = {
  data: HeroAsset_data$key;
};

export const HeroAsset: FC<HeroAssetComponentProps> = (props) => {
  const data = useFragment(dataFragment, props.data);
  const { url, height, width } = data.image;

  return (
    <ImageAsset
      src={url}
      alt=""
      aria-hidden="true"
      width={width}
      height={height}
      data-testid="hero-asset"
      sizes="
        (max-width: 500px) 13vw,
        128px"
    />
  );
};
