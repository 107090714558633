/**
 * @generated SignedSource<<a1eae75721a5518de949bea1b9153c65>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type HeroGameDecorations_data$data = {
  readonly assets: {
    readonly bottomLeft: {
      readonly " $fragmentSpreads": FragmentRefs<"HeroAsset_data">;
    } | null;
    readonly bottomRight: {
      readonly " $fragmentSpreads": FragmentRefs<"HeroAsset_data">;
    } | null;
    readonly topLeft: {
      readonly " $fragmentSpreads": FragmentRefs<"HeroAsset_data">;
    } | null;
    readonly topRight: {
      readonly " $fragmentSpreads": FragmentRefs<"HeroAsset_data">;
    } | null;
  };
  readonly game: {
    readonly familyName: string;
    readonly new: boolean;
    readonly turnoverContribution: number | null;
    readonly " $fragmentSpreads": FragmentRefs<"useGetTurnoverContribution_game">;
  };
  readonly icon: string | null;
  readonly " $fragmentType": "HeroGameDecorations_data";
};
export type HeroGameDecorations_data$key = {
  readonly " $data"?: HeroGameDecorations_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"HeroGameDecorations_data">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "args": null,
    "kind": "FragmentSpread",
    "name": "HeroAsset_data"
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "HeroGameDecorations_data",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Game",
      "kind": "LinkedField",
      "name": "game",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "new",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "familyName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "turnoverContribution",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "useGetTurnoverContribution_game"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "icon",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "HeroGameAssetFields",
      "kind": "LinkedField",
      "name": "assets",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "HeroAsset",
          "kind": "LinkedField",
          "name": "bottomLeft",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "HeroAsset",
          "kind": "LinkedField",
          "name": "bottomRight",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "HeroAsset",
          "kind": "LinkedField",
          "name": "topLeft",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "HeroAsset",
          "kind": "LinkedField",
          "name": "topRight",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "HeroGame",
  "abstractKey": null
};
})();

(node as any).hash = "9fc9d2a63dd9de494c46ce36c67dd396";

export default node;
